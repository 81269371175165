import Vue from 'vue';
import VueI18n from 'vue-i18n';
import thTH from './lang/th_th.js';
import ptBR from './lang/pt_br.js';
import EN from './lang/en.js';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'th_TH',
  messages: {
    en: EN,
    th_th: thTH,
    pt_br: ptBR,
  },
});
export default i18n;
