<template>
  <div class="bullet-wrap">
    <div
      class="bullet-item"
      :class="[backgroundColor(item.color), {'add-padding': item.showGood}]"
      :style="`animation-duration: ${getWidth()}s;`"
      v-for="item in showingBullets"
      @animationend="removeBullet(item)"
      :key="item.id"
      :data-line="item.line"
    >
      <span v-if="item.showGood" class="good">
        <img :src="$requireSafe(`good.svg`)" alt="good">
      </span>
      {{ item.acc }}
      {{ item.name }}
    </div>
  </div>
</template>

<script>
import danmuText from '@/assets/js/danmuText.js';

const getUUID = () => Math.random() + Math.random();
const randomAccount = () => Math.random().toString(36).slice(-3);
const random = (max, min) => Math.floor(Math.random() * (max - min + 1)) + min;

export default {
  props: {
    siteId: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      waitBullets: [],
      showingBullets: [],
      lines: 3,
      currentLine: 1,
      isInfinite: true,
    };
  },
  mounted() {
    const vm = this;
    vm.getWidth();
    window.addEventListener('resize', vm.getWidth);
    vm.shuffleSort(danmuText.text).forEach((e) => {
      vm.waitBullets.push({
        id: getUUID(),
        acc: `${randomAccount()}***${randomAccount()}`,
        name: e,
        line: 0,
        color: random(3, 1),
        showGood: random(10, 1) % 2 === 0,
      });
    });

    vm.showNextBullet();
    const timer = setInterval(this.showNextBullet, 1500);
    // 组件销毁前，清除定时器
    this.$once('hook:beforeDestroy', () => {
      clearInterval(timer);
    });
  },
  methods: {
    getWidth() {
      const times = Math.floor(window.innerWidth / 100);
      if (times <= 10 && times > 7) {
        return 11;
      } if (times <= 7) {
        return 8;
      }
      return times;
    },
    showNextBullet() {
      const vm = this;
      if (!vm.waitBullets.length) {
        return;
      }
      // 先确定弹道，跟上一个弹道错开即可
      vm.currentLine = (vm.currentLine % vm.lines) + 1;
      // 从等待集合里取出第一个
      const currentBullet = vm.waitBullets.shift();
      // 想要无限循环的话
      this.isInfinite
        && this.waitBullets.push({
          id: getUUID(),
          acc: `${randomAccount()}***${randomAccount()}`,
          name: currentBullet.name,
          line: 0,
          color: random(3, 1),
          showGood: random(10, 1) % 2 === 0,
        });
      // 设置弹幕的弹道
      currentBullet.line = vm.currentLine;
      // 弹幕放进显示集合里，弹幕开始滚动
      // vm.showingBullets.push(currentBullet);
    },
    removeBullet(bullet) {
      const index = this.showingBullets.findIndex(
        (item) => item.id === bullet.id,
      );
      this.showingBullets.splice(index, 1);
    },
    backgroundColor(item) {
      switch (item) {
        case 1:
          return 'blue';
        case 2:
          return 'green';
        case 3:
          return 'yellow';
        default:
          return 'bule';
      }
    },
    shuffleSort(arr) {
      return arr.sort(() => Math.random() - 0.5);
    },
  },
};
</script>

<style lang="scss" scoped>

.bullet-wrap {
  position: relative;
  width: 100%;
  height: 150px;

  @include mediaquery_minipad {
    height: 73px;
  }

  .bullet-item {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    padding: 0 15px;
    border-radius: 23px;
    color: #fff;
    font-size: 14px;
    white-space: nowrap;
    animation-name: rightToLeft;
    animation-timing-function: linear;
    transform: translateX(100vw);
    box-shadow: 0 3px 6px #00000029;

    @include mediaquery_minipad {
      height: 27px;
      // animation: rightToLeft 7s linear both;
    }

    &.blue {
      background: transparent linear-gradient(91deg, #4178a2 0%, #165c81 100%) 0% 0% no-repeat padding-box;
    }

    &.green {
      background: transparent linear-gradient(90deg, #3d9f85 0%, #178263 100%) 0% 0% no-repeat padding-box;
    }

    &.yellow {
      background: transparent linear-gradient(90deg, #e29d11 0%, #bc8008 100%) 0% 0% no-repeat padding-box;
    }

    &.add-padding {
      padding-left: 38px;

      @include mediaquery_minipad {
        padding-left: 34px;
      }
    }

    .good {
      position: absolute;
      top: 0;
      left: 0;
      width: 30px;

      @include mediaquery_minipad {
        width: 27px;
      }
    }
  }

  .bullet-item[data-line='1'] {
    top: 15px;
  }

  .bullet-item[data-line='2'] {
    top: 70px;
  }

  .bullet-item[data-line='3'] {
    top: 125px;
  }

  @include mediaquery_minipad {
    .bullet-item[data-line='1'] {
      top: 85px;
    }

    .bullet-item[data-line='2'] {
      top: 122px;
    }

    .bullet-item[data-line='3'] {
      top: 159px;
    }
  }

  @include mediaquery_phone {
    .bullet-item[data-line='1'] {
      top: 62px;
    }

    .bullet-item[data-line='2'] {
      top: 99px;
    }

    .bullet-item[data-line='3'] {
      top: 136px;
    }
  }

  @keyframes rightToLeft {
    0% {
      transform: translate(100vw);
    }

    100% {
      transform: translate(-100%);
    }
  }
}

</style>
