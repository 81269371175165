export default {
  copyright: 'COPYRIGHT © 2023 {renderHostName}',
  copyright2: 'All Rights Reserved.',
  text1: 'More Entertainment',
  text2: 'All In ',
  rightNow: 'Start Game',
  homePage: 'Website Entrance',
  download: 'Download The App',
  register: 'Apply For Membership',
  service: 'Online Customer Service',
};
