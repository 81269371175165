export default {
  copyright: 'COPYRIGHT © 2023 {renderHostName}',
  copyright2: 'All Rights Reserved.',
  footerText1: 'FIBA',
  footerText2: 'Global partners',
  text1: '2Copa do Mundo da FIFA 2026 Mais emoção',
  text2: 'Nos gloriosos corredores do',
  text3: 'só há uma coisa que você pode fazer, e isso não quer dizer que você não perderá.',
  text4: 'Para pintar o cálice dourado dos sonhos com as cores da glória.',
  spokesperson: 'Porta-voz',
  spokesperson1: 'Karim Benzema',
  rightNow: 'Comece agora',
  homePage: 'Acesse o site oficial',
  download: 'Download do APP',
  register: 'Registro',
  service: 'Atendimento ao cliente on-line',
  close: 'Coagulação',
  enter: 'Entre agora',
};
