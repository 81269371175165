<template>
  <div id="app">
    <div class="cr" :style="[{ backgroundImage: `url(${bgImg})` }]">
      <transition name="fade">
        <div v-if="isToastShow" class="toast">
          <img class="toast-icon" src="@/assets/img/warning.svg" alt="">
          <span class="toast-text">{{ toastMsg }}</span>
        </div>
      </transition>

      <!-- 手機的header -->
      <header class="header">
        <img :src="$requireSafe(`logo.webp`)" alt="logo">
      </header>

      <div class="pc">

        <danmu :site-id="siteId" />

        <div class="view">
          <!-- <div class="phone">
            <img :src="$requireSafe(`phone.webp`)" alt="phone">
          </div> -->

          <div class="middle">
            <!-- <div class="sign">
              <div class="sign-img">
                <img :src="$requireSafe(`sign.png`)" alt="sign">
              </div>

              <div class="sign-text">
                <div class="sign-text-top">
                  {{ `${renderHostName}${$t('spokesperson')}` }}
                </div>

                <div class="sign-text-bottom">{{ $t('spokesperson1') }}</div>
              </div>
            </div> -->

            <div class="people">
              <img :src="$requireSafe(`people-new.png`)" alt="people">
            </div>
          </div>

          <div class="right">
            <div class="right-top">
              <div class="right-top-logo">
                <img :src="$requireSafe(`logo.webp`)" alt="logo">
              </div>

              <div class="right-top-text">
                <div class="first">{{ $t('text1') }}</div>
                <div class="second second-logo">
                  <div class="second-left">
                    <div class="" style="margin-bottom: 5px;">Mais emoção no</div>
                    <div class="">Más emoción en</div>
                  </div>
                  <div class="second-right">
                    <div class="second-name">{{ ` ${renderHostName}` }}</div>
                  </div>
                </div>
                <!-- <div class="third">
                  <div>{{ $t('text3') }}</div>
                  <div>{{ $t('text4') }}</div>
                </div> -->
              </div>

              <div class="enter-button" @click="goUrl(2)">
                <!-- {{ $t('rightNow') }} -->
              </div>
            </div>

            <div class="right-bottom">
              <div class="right-bottom-first first">
                <div v-for="item in firstButtomList" :key="item.key" class="right-bottom-first-item"
                  :class="{ 'tool-tip-button': item.key === 0 }"
                  @click="goUrl(item.key)">
                  <div class="icon">
                    <img :src="$requireSafe(`${item.img}`)" :alt="item.label2">
                  </div>

                  <div class="text">
                    <div class="text-first">{{ $t(item.label) }}</div>
                    <!-- <div class="text-second">{{ item.label2 }}</div> -->
                  </div>
                </div>

                <div v-show="isShowTooltip" class="tooltip">
                  <template v-if="urlList && urlList.length">
                    <div v-for="(item, idx) in urlList" :key="`pingArr[${idx}]`" class="row">
                      <div class="square ping fz-sm">
                        {{ item && item.ping }}ms
                      </div>

                      <img class="arrow" :src="require('@/assets/img/arrow.svg')" alt="">

                      <div class="square url fz-sm">
                        {{ formatUrl(item && item.url) }}
                      </div>

                      <a href="javascript:void(0)" class="square enter fz-sm"
                        :class="{ 'enter--disabled': item && item.disabled }"
                        @click="goWebsite(item && item.url, item && item.disabled)">
                        {{ item && item.disabled ? $t('close') : $t('enter') }}
                      </a>
                    </div>
                  </template>
                </div>
              </div>

              <div class="right-bottom-first">
                <div v-for="item in secondButtomList" :key="item.key" class="right-bottom-first-item"
                  @click="goUrl(item.key)">
                  <div class="icon">
                    <img :src="$requireSafe(`${item.img}`)" :alt="item.label2">
                  </div>

                  <div class="text">
                    <div class="text-first">{{ $t(item.label) }}</div>
                    <!-- <div class="text-second">{{ item.label2 }}</div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="footer">
          <!-- <div class="footer-left">
            <div class="first">
              <img :src="$requireSafe(`ball.svg`)" alt="ball">
            </div>
            <div class="second">
              <img :src="$requireSafe(`trophy.png`)" alt="trophy">
            </div>
            <div class="third">
              <img :src="$requireSafe(`fiba.svg`)" alt="fiba">
            </div>

            <div class="text">
              <div>{{ $t('footerText1') }}</div>
              <div>{{ $t('footerText2') }}</div>
            </div>
          </div> -->

          <div class="footer-right">
            <div>{{ $t('copyright', { renderHostName: renderHostName }) }}</div>
            <div>{{ $t('copyright2') }}</div>
          </div>
        </div>
      </div>

      <div class="mobile">
        <danmu :site-id="siteId" />

        <div class="view">
          <!-- <div class="sign">
              <div class="sign-img">
                <img :src="$requireSafe(`sign.png`)" alt="sign">
              </div>

              <div class="sign-text">
                <div class="sign-text-top">
                  {{ `${renderHostName}${$t('spokesperson')}` }}
                </div>

                <div class="sign-text-bottom">{{ $t('spokesperson1') }}</div>
              </div>

              <div class="sign-logo">
                <div class="first">
                  <img :src="$requireSafe(`ball.svg`)" alt="ball">
                </div>
                <div class="second">
                  <img :src="$requireSafe(`trophy.png`)" alt="trophy">
                </div>
                <div class="third">
                  <img :src="$requireSafe(`fiba.svg`)" alt="fiba">
                </div>
              </div>
            </div> -->

          <div class="people">
            <img :src="$requireSafe(`people-new.png`)" alt="people">
          </div>
        </div>

        <div class="footer" :style="{ backgroundImage: `url(${footerImg})` }">
          <div class="footer-top">
            <div class="one">{{ $t('text1') }}</div>
            <div class="two second-logo">
                  <div class="second-left">
                    <div class="" style="margin-bottom: 5px;">Mais emoção no</div>
                    <div class="">Más emoción en</div>
                  </div>
                  <div class="second-right">
                    <div class="second-name">{{ ` ${renderHostName}` }}</div>
                  </div>
                </div>
          </div>

          <div class="footer-middle">
            <div class="footer-middle-first first">
              <div v-for="item in firstButtomList" :key="item.key" class="footer-middle-first-item"
                :class="{ 'tool-tip-button': item.key === 0 }"
                @click="goUrl (item.key)">
                <div class="icon">
                  <img :src="$requireSafe(`${item.img}`)" :alt="item.label2">
                </div>

                <div class="text">
                  <div class="text-first">{{ $t(item.label) }}</div>
                  <!-- <div class="text-second">{{ item.label2 }}</div> -->
                </div>
              </div>

              <div v-show="isShowTooltip" class="tooltip">
                <template v-if="urlList && urlList.length">
                  <div v-for="(item, idx) in urlList" :key="`pingArr[${idx}]`" class="row">
                    <div class="square ping fz-sm">
                      {{ item.ping }}ms
                    </div>

                    <img class="arrow" :src="require('@/assets/img/arrow.svg')" alt="">

                    <div class="square url fz-sm">
                      {{ item.url }}
                    </div>

                    <a href="javascript:void(0)" class="square enter fz-sm" :class="{ 'enter--disabled': item.disabled }"
                      @click="goWebsite(item.url, item.disabled)">
                      {{ item.disabled ? $t('close') : $t('enter') }}
                    </a>
                  </div>
                </template>
              </div>
            </div>

            <div class="footer-middle-first">
              <div v-for="item in secondButtomList" :key="item.key" class="footer-middle-first-item"
                @click="goUrl(item.key)">
                <div class="icon">
                  <img :src="$requireSafe(`${item.img}`)" :alt="item.label2">
                </div>

                <div class="text">
                  <div class="text-first">{{ $t(item.label) }}</div>
                  <!-- <div class="text-second">{{ item.label2 }}</div> -->
                </div>
              </div>
            </div>
          </div>

          <div class="footer-bottom">
            {{ `${$t('copyright', { renderHostName: renderHostName })} ${$t('copyright2')}` }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SiteApi from '@/assets/js/api/siteApi.js';
import danmu from '@/danmu.vue';

const maxCount = 10;
const $requireSafe = (path) => {
  try {
    /* eslint-disable */
    return require(`@/assets/img/${path}`);
  } catch (err) {
    return undefined;
  }
};

export default {
  name: 'App',
  components: {
    danmu,
  },
  data() {
    return {
      firstButtomList: [
        {
          label: 'Plataforma Brasil', label2: 'INDEX', img: 'home.svg', key: 0,
        },
        {
          label: 'Plataforma México', label2: 'DOWNLOAD', img: 'home.svg', key: 1,
        },
      ],
      secondButtomList: [
        // {
        //   label: 'register', label2: 'SIGN UP NOW', img: 'register.svg', key: 2,
        // },
        {
          label: 'ONLINE SERVICE', label2: 'ONLINE SERVICE', img: 'service.svg', key: 3,
        },
      ],
      isShowTooltip: false,
      urlList: [],
      appUrl: '',
      serviceUrl: '',
      locale: '',
      siteId: '',
      sharedUrl: '',
      indexList: [],
      isMobile: false,
      toastMsg: '',
      isToastShow: false,
      randoms: []
    };
  },
  computed: {
    containerHeight() {
      const vm = this;
      if (!vm.isMobile || !vm.isShowTooltip) return {};
      return {
        minHeight: '700px',
      };
    },
    renderHostName() {
      return window._jsvar?.siteName || '';
    },
    bgImg() {
      const vm = this;
      return $requireSafe(`bg-sm.png`);
    },
    footerImg() {
      const vm = this;
      // return $requireSafe(`bg-footer.svg`);
    },
    domainList() {

    }
  },
  async created() {
    const vm = this;
    const { siteId } = window._jsvar;
    vm.siteId = siteId;
    await vm.setLocale();
    vm.setTitle();
    vm.setUrlList();
    await vm.getPingArr();
    vm.getServiceAndAppUrl();
  },
  mounted() {
    const vm = this;
    vm.handleResize();
    window.addEventListener('resize', vm.handleResize);
  },
  beforeDestroy() {
    const vm = this;
    window.removeEventListener('resize', vm.handleResize);
  },
  methods: {
    setTitle() {
      const vm = this;
      document.title = window._jsvar?.siteTitle?.[vm.locale] || 'Welcome';
      const favicon = document.getElementById('favicon');
      favicon.href = `favicon.ico`;
    },
    showToast(msg, duration = 3000) {
      const vm = this;
      vm.toastMsg = msg;
      vm.isToastShow = true;
      setTimeout(() => {
        vm.isToastShow = false;
        vm.toastMsg = '';
      }, duration);
    },
    getRandomNum() {
      const vm = this;

      while (vm.randoms.length < window._jsvar?.siteDomainList?.[vm.locale]?.length) {
        const randomNum = Math.floor(Math.random() * window._jsvar?.siteDomainList?.[vm.locale]?.length);

        if (!vm.randoms.includes(randomNum)) {
          vm.randoms.push(randomNum)
        }
      }
    },
    setLocale() {
      const vm = this;
      const { siteLocale } = window._jsvar;
      let useLang = siteLocale;
      const deviceLang = navigator.language.replace('-', '_');
      const supportLangList = ['th_TH', 'pt_BR']
      if (supportLangList.includes(deviceLang)) {
        useLang = deviceLang.toLowerCase();
      }
      // vm.locale = useLang;
      // vm.$i18n.locale = useLang;
      vm.locale = 'en';
      vm.$i18n.locale = 'en';
    },
    setUrlList() {
      const vm = this;
      const { siteDomainList } = window._jsvar;
      const allList = siteDomainList?.[vm.locale].map((item) => ({
        url: item,
        ping: '10',
        disabled: false,
      }));

      vm.getRandomNum();

      vm.randoms.forEach((item) => {
        vm.urlList.push(allList[item])
      })

    },
    checkDevice() {
      const vm = this;
      vm.isMobile = window.matchMedia('(max-width: 768px)').matches;
    },
    handleFullHeight() {
      const vm = this;
      const vh = window.innerHeight * 0.01;
      document.querySelector('.cr').style.setProperty('--vh', `${vh}px`);
      vm.fullVh = vh * 100;
    },
    handleResize() {
      const vm = this;
      vm.handleFullHeight();
      vm.checkDevice();
    },
    getPingArr() {
      const vm = this;

      const storagePingArr = [];
      vm.urlList.forEach(() => {
        storagePingArr.push([]);
      });
      vm.urlList.forEach((item, index) => {
        const loadTimer = setInterval(async () => {
          const { time, disabled } = await vm.getLoadTime(`${item.url}/favicon.ico`);
          storagePingArr[index].push(time);
          vm.$set(item, 'ping', time);
          vm.$set(item, 'disabled', disabled);
          vm.indexList.push(index);
          if (storagePingArr[index].length === maxCount) {
            for (let i = 0; i < 2; i++) {
              const maxPing = Math.max.apply(null, storagePingArr[index]);
              storagePingArr[index].splice(storagePingArr[index].indexOf(maxPing), 1);
            }
            const finalPing = vm.getArrayAvg(storagePingArr[index]);
            item.ping = Math.round(finalPing);

            clearInterval(loadTimer);
            if (index === vm.indexList[vm.indexList.length - 1] && vm.indexList.length === maxCount * vm.urlList.length) {
              vm.urlSort();
            }
          }
        }, 1000);
      });
    },
    getLoadTime(url) {
      return new Promise(((resolve) => {
        const img = new Image();
        const timeStart = new Date();
        img.src = url;
        img.onerror = function run() {
          const timeEnd = new Date();
          resolve({ time: timeEnd.getTime() - timeStart.getTime(), disabled: true });
        };
        img.onload = function run() {
          const timeEnd = new Date();
          resolve({ time: timeEnd.getTime() - timeStart.getTime(), disabled: false });
        };
      }));
    },
    urlSort() {
      const vm = this;
      vm.urlList.sort((a, b) => {
        if (a.disabled === b.disabled) {
          return a.ping - b.ping;
        }

        return a.disabled - b.disabled;
      });
    },
    getArrayAvg(array) {
      const len = array.length;
      let sum = 0;
      for (let i = 0; i < len; i++) {
        sum += array[i];
      }
      return sum / len;
    },
    async getServiceAndAppUrl() {
      const vm = this;
      const urlList = vm.urlList.filter((e) => !e.disabled);
      for (let i = 0; i < vm.urlList.length; i++) {
        SiteApi.SERVER = `${urlList[i].url}/api`;
        vm.sharedUrl = `${urlList[i].url}?a=x&c=`;
        // 需求是 call api 不如預期才進下一個迭代，故 disable eslint 的限制
        // eslint-disable-next-line
        const [service, app] = await Promise.all([SiteApi.getServiceUrl(vm.locale), SiteApi.getAppUrl(vm.locale)])

        if (service.code === 200 && app.code === 200) {
          if (window._jsvar?.siteServiceUrl?.[vm.locale]) {
            vm.serviceUrl = window._jsvar?.siteServiceUrl?.[vm.locale];
          } else {
            vm.serviceUrl = service.data.serviceUrl.Online.links;
          }

          break;
        }


        if (i === vm.urlList.length - 1 && service.code !== 200) {
          vm.showToast('Try again later');
        }
      }
    },
    toggleTooltip(isShow) {
      const vm = this;
      vm.isShowTooltip = isShow;
    },
    goWebsite(item, disabled) {
      if (disabled) return;

      if (window._jsvar?.siteAgentCode) {
        window.location = `${item}?a=x&c=${window._jsvar?.siteAgentCode}`
      } else if (window._jsvar?.siteRedirectRegister) {
        window.location.href = `${item}?a=x`;
      } else {
        window.location.href = item;
      }
    },
    goUrl(item) {
      const vm = this;
      let url;
      switch (item) {
        case 0:
          url = 'https://r3jogo.com';
          break;
        case 1:
          url = 'https://r3juego.com';
          break;
        case 2:
          url = 'https://t.me/R3casino';
          break;
        case 3:
          url = 'https://t.me/R3casino';
          break;
        default:
          break;
      }
      window.location.href = url;
    },
    formatUrl(url) {
      return url.replace('https://', 'http://')
    }
  },
};
</script>

<style lang="scss" scoped>
.cr {
  $this: &;

  position: relative;
  justify-content: space-between;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  @include mediaquery_phone {
    background-size: cover;
  }

  .header {
    position: absolute;
    z-index: 2;
    display: none;
    width: 100%;
    height: 56px;
    padding: 8px 0;
    background: #050623;

    @include mediaquery_minipad {
      @include flex-center;
    }

    @include mediaquery_phone {
      min-height: 40px;
    }

    img {
      height: 100%;
    }
  }

  .pc {
    height: 100%;
    overflow: hidden;

    .view {
      display: flex;
      justify-content: space-around;
      max-width: 100%;
      height: calc(100% - 150px);
      margin: 0 auto;
      padding: 0 165px 165px;

      @include mediaquery_minipc {
        padding: 0 160px;
      }

      .middle {
        position: relative;
        width: 90%;
        height: 100%;

        @include mediaquery_pc {
          width: 90%;
        }

        @include mediaquery_pad {
          width: 80%;
        }

        .people {
          width: 500px;
          width: 90%;
          height: 100%;

          img {
            width: 100%;
          }
        }
      }

      .right {
        // z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 40%;
        margin: 20px;

        @include mediaquery_pc {
          width: 20%;
        }

        @include mediaquery_pad {
          width: 15%;
        }

        &-top {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-bottom: 40px;
          color: #fff;

          &-logo {
            width: 500px;

            img {
              width: 100%;
            }

            @include mediaquery_minipc {
              width: 300px;
            }
          }

          &-text {
            display: flex;
            flex-direction: column;
            align-items: center;

            .first {
              margin-bottom: 40px;
              color: #f1f1f1;
              font-size: 32px;
              white-space: pre-line;

              @include mediaquery_minipc {
                font-size: 24px;
              }

              @include mediaquery_pad {
                font-size: 24px;
              }
            }

            .second {
              margin: 10px;
              margin-bottom: 20px;
              color: #f6ef27;
              font-weight: bold;
              font-size: 30px;

              @include mediaquery_minipc {
                font-size: 30px;
              }

              @include mediaquery_pad {
                font-size: 26px;
              }
            }

            .second-logo {
              display: flex;
              flex-direction: row;

              .second-right {
                display: flex;
                align-items: center;
                justify-items: center;
                margin-left: 10px;
                color: #f74343;
              }
            }
          }

          &-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 200px;
            height: 49px;
            margin-bottom: 40px;
            border: 1px solid #908050;
            border-radius: 25px;
            overflow: hidden;
            color: #000;
            font-weight: bold;
            font-size: 20px;
            background: transparent linear-gradient(2deg, #daa71a 0%, #fec521 100%) 0% 0% no-repeat padding-box;
            cursor: pointer;
            box-shadow: 0 3px 6px #0003;

            @include mediaquery_minipc {
              width: 180;
              font-size: 16px;
            }
          }
        }

        &-bottom {
          width: 100%;
          height: 75%;

          &-first {
            display: flex;
            align-items: center;
            justify-content: center;

            &.first {
              position: relative;
              margin-bottom: 20px;
            }

            &-item {
              position: relative;
              display: flex;
              flex: 0 0 250px;
              align-items: center;
              height: 120px;
              padding: 0 10px 0 20px;
              border: 1px solid #fff3;
              border-radius: 10px;
              color: #fff;
              background: rgba(0, 0, 0, 0.3);
              cursor: pointer;
              box-shadow: 0 3px 6px #0003;
              backdrop-filter: blur(3px);

              @include mediaquery_minipc {
                width: 208px;
              }

              @include mediaquery_ipad {
                padding: 0 10px;
              }

              &:first-child {
                margin-right: 20px;
              }

              &:hover {
                border: 1px solid #ffb31a80;
                color: #ffb31a;
              }

              .icon {
                flex: 0 0 30px;
                height: 40px;
                margin-right: 20px;

                img {
                  width: 100%;
                }

                @include mediaquery_minipc {
                  // margin-right: 10px;
                }
              }

              .text {
                &-first {
                  margin-bottom: 10px;
                  font-size: 18px;

                  @include mediaquery_minipc {
                    font-size: 18px;
                  }

                  @include mediaquery_ipad {
                    font-size: 16px;
                  }
                }

                &-second {
                  color: #fff;
                  font-size: 16px;
                  opacity: 0.6;

                  @include mediaquery_minipc {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .enter-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 49px;
    }

    .footer {
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 170px;
      padding: 50px 0 0 110px;
      color: #fff;
      font-size: 14px;
      background: transparent linear-gradient(180deg, #0000 0%, #0009 100%) 0% 0% no-repeat padding-box;

      &-right {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 45px;
        line-height: 1.5;
        opacity: 0.5;
      }
    }

    @include mediaquery_minipad {
      display: none;
    }
  }

  .mobile {
    display: none;
    height: 100%;
    overflow: hidden;

    @include mediaquery_minipad {
      display: block;
    }

    .bullet-wrap {
      height: 100px;
    }

    .view {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      margin: 0 20px;

      @include mediaquery_phone {
        justify-content: center;
      }

      .people {
        z-index: 2;
        width: 373px;
        height: 100%;

        img {
          width: 95%;
        }

        @include mediaquery_phone {
          width: 360px;
        }
      }
    }

    .footer {
      position: relative;
      bottom: 0;
      z-index: 3;
      width: 100%;
      height: 296px;
      padding-bottom: 350px;
      color: #fff;
      background-size: contain;

      &-top {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 30px;

        .one {
          margin-bottom: 20px;
          color: #fff;
          font-size: 16px;
        }

        .two {
          display: flex;
          flex-direction: row;
          margin-top: 5px;
          margin-bottom: 30px;
          color: #fee819;
          font-weight: bold;
          font-size: 24px;

          .second-right {
            display: flex;
            align-items: center;
            justify-items: center;
            margin-left: 10px;
            color: #f74343;
          }
        }
      }

      &-middle {
        width: 100%;

        &-first {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin-top: 20px;

          &.first {
            margin-bottom: 10px;
          }

          &-item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 45%;
            height: 62px;
            padding: 0 5px;
            border: 1px solid #fff3;
            border-radius: 5px;
            background: rgba(0, 0, 0, 0.3);
            cursor: pointer;
            box-shadow: 0 3px 6px #0003;

            &:hover {
              border: 1px solid#FFB31A80;
              color: #ffb31a;
            }

            &:first-child {
              margin-right: 10px;
            }

            .icon {
              width: 26px;
              margin-right: 10px;
              margin-left: 10px;

              img {
                width: 100%;
              }
            }

            .text {
              display: flex;
              flex: 1 0 0;
              flex-direction: column;

              &-first {
                margin-bottom: 3px;
                font-size: 14px;
              }

              &-second {
                font-size: 12px;
                opacity: 0.6;
              }
            }
          }
        }
      }

      &-bottom {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 70px;
        font-size: 12px;
        opacity: 0.5;
      }
    }
  }
}

.tooltip {
  position: absolute;
  right: 104%;
  bottom: -30%;
  z-index: 5;
  padding: 10px 20px 20px;
  border: 1px solid #ffffff4d;
  border-radius: 5px;
  background: #776129;

  @include mediaquery_minipc {
    top: -155%;
    right: unset;
    bottom: unset;
  }

  @include mediaquery_pad {
    right: -50px;
  }

  @include mediaquery_minipad {
    top: unset;
    right: 0;
    bottom: 220px;
    left: 0;
    margin: 0 15px;
    transform: none;
  }

  &::before,
  &::after {
    position: absolute;
    width: 0;
    height: 0;
    content: '';

    @include mediaquery_minipad {
      left: 25%;
    }
  }

  &::before {
    top: 62%;
    right: -20px;
    border-top: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #ffffff4d;
  }

  &::after {
    top: 62%;
    right: -18px;
    border-top: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #776129;
  }

  @include mediaquery_minipc {
    &::before {
      top: unset;
      right: unset;
      bottom: -19px;
      left: 140px;
      border-top: 10px solid #ffffff4d;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
    }

    &::after {
      top: unset;
      right: unset;
      bottom: -18px;
      left: 140px;
      border-top: 10px solid #041415;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
    }
  }

  @include mediaquery_pad {
    &::before {
      top: unset;
      right: unset;
      bottom: -19px;
      left: 220px;
      border-top: 10px solid #ffffff4d;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
    }

    &::after {
      top: unset;
      right: unset;
      bottom: -18px;
      left: 220px;
      border-top: 10px solid #041415;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
    }
  }

  @include mediaquery_minipad {
    &::before {
      top: unset;
      right: unset;
      bottom: -19px;
      left: 85px;
      border-top: 10px solid #ffffff4d;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
    }

    &::after {
      top: unset;
      right: unset;
      bottom: -18px;
      left: 85px;
      border-top: 10px solid #041415;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
    }
  }

  .row {
    @include flex-expand;

    margin-top: 10px;
  }

  .square {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    border-radius: 2px;
    color: #333;
    background: #ebebeb;

    @include mediaquery_minipad {
      height: 26px;
      padding: 6px 0;
    }
  }

  .ping {
    min-width: 80px;
    text-align: center;

    @include mediaquery_minipad {
      min-width: 56px;
    }
  }

  .url {
    flex: 1 0 0;
    min-width: 240px;
    padding: 8px 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @include mediaquery_minipad {
      min-width: 120px;
      padding: 6px 10px;
    }
  }

  .arrow {
    width: 26px;
    margin: 0 12px;

    @include mediaquery_minipad {
      margin: 0 5px;
    }
  }

  .enter {
    width: 120px;
    margin-left: 20px;
    border-color: #fdecc2;
    color: #000;
    font-weight: bold;
    text-align: center;
    background: #fdecc2;

    &--disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    @include mediaquery_minipad {
      width: 74px;
      margin-left: 5px;
    }
  }
}

.toast {
  position: fixed;
  top: 50px;
  left: 50%;
  z-index: 1000;
  display: flex;
  align-items: center;
  min-width: 80px;
  padding: 10px 15px;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 4px 12px #00000026;
  transform: translate3d(-50%, 0, 0);

  &-icon {
    width: 16px;
  }

  &-text {
    margin-left: 5px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
