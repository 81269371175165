export default {
  copyright: 'COPYRIGHT © 2023 {renderHostName}',
  copyright2: 'All Rights Reserved.',
  text1: 'ความบันเทิงมากมาย',
  text2: 'ใน ',
  rightNow: 'เริ่มเล่นเกมส์',
  homePage: 'ทางเข้าเว็บไซต์',
  download: 'ดาวน์โหลดแอพ',
  register: 'สมัครสมาชิก',
  service: 'บริการออนไลน์',
};
